import {ELanguage} from "../../i18n.ts"
import {TRollbarUserInfo} from "../../services"
import {EFeature} from "../featureFlags.ts"

export const CURRENT_LS_VERSIONS = {
  lastUser: 2,
  language: 2,
  features: 1,
  pageSizes: 1,
} as const satisfies {[key in TLSAllTimeKey]?: number}

export type TLSValues = TWithVersionZero<{
  language: [string, ELanguage]
  lastUser: [Omit<TRollbarUserInfo, "isMasqueraded">, TRollbarUserInfo]
  features: [EFeature[]]
  pageSizes: [{[tableName: string]: number}]
}>

const LS_PREFIX = "CloseRocket_"

export type TJSONValue = number | string | boolean | null | TJSONValue[] | {[key: string]: TJSONValue}

type TWithVersionZero<T extends {[key: string]: TJSONValue[]}> = {[key in keyof T]: [never, ...T[key]]}

export type TLSAllTimeKey = keyof TLSValues
export type TLSVersion<TKey extends TLSAllTimeKey> = keyof TLSValues[TKey] & number
export type TLSValue<TKey extends TLSAllTimeKey, TVersion extends TLSVersion<TKey>> = TLSValues[TKey][TVersion]

export type TLSCurrentKey = keyof typeof CURRENT_LS_VERSIONS
export type TLSCurrentVersion<TKey extends TLSCurrentKey> = (typeof CURRENT_LS_VERSIONS)[TKey]

export type TLSItem<TKey extends TLSAllTimeKey = any, TVersion extends TLSVersion<TKey> = any> = {
  version: TVersion
  data: TLSValues[TKey][TVersion]
}
export type TLSItemWithKey<TKey extends TLSAllTimeKey = any, TVersion extends TLSVersion<TKey> = any> = {
  key: TKey
} & TLSItem<TKey, TVersion>

const makeLSKey = (name: string) => `${LS_PREFIX}${name}`

export function getLSItem<TKey extends TLSCurrentKey = any>(
  key: TKey
): TLSItem<TKey, TLSCurrentVersion<TKey>> | undefined {
  const stringItem = globalThis.localStorage?.getItem(makeLSKey(key))

  if (stringItem == null) {
    return undefined
  }

  return JSON.parse(stringItem)
}

export function setLSItem<TKey extends TLSCurrentKey = any>(key: TKey, data: TLSValue<TKey, TLSCurrentVersion<TKey>>) {
  globalThis.localStorage?.setItem(
    makeLSKey(key),
    JSON.stringify({version: CURRENT_LS_VERSIONS[key], data} satisfies TLSItem)
  )
  window.dispatchEvent(new StorageEvent("storage"))
}

export function deleteLSItem(key: TLSCurrentKey) {
  globalThis.localStorage?.removeItem(makeLSKey(key))
  window.dispatchEvent(new StorageEvent("storage"))
}

function getAllLSItemKeys(): string[] {
  return Object.keys(globalThis.localStorage ?? {})
    .filter(key => key.startsWith(LS_PREFIX))
    .map(key => key.substring(LS_PREFIX.length))
}

export function getAllLSItems(): TLSItemWithKey[] {
  return getAllLSItemKeys().reduce((items, key) => {
    const item = getLSItem(key as TLSCurrentKey)

    if (item) {
      items.push({key, ...item})
    }

    return items
  }, [] as TLSItemWithKey[])
}

export function deleteAllLSItems() {
  getAllLSItemKeys().forEach(key => deleteLSItem(key as TLSCurrentKey))
  window.dispatchEvent(new StorageEvent("storage"))
}
