import {getLSItem, setLSItem} from "./localStorage"

export enum EFeature {
  SHOW_SALESPERSON_RATINGS = "SHOW_SALESPERSON_RATINGS",
}

function getEnabledFeatures(): EFeature[] {
  return getLSItem("features")?.data ?? []
}

export function isFeatureEnabled(...features: EFeature[]): boolean {
  const enabledFeatures = getEnabledFeatures()

  return features.every(feature => enabledFeatures.includes(feature))
}

// Initialize LS
if (!getLSItem("features")) {
  setLSItem("features", [])
}
