import twForms from "@tailwindcss/forms"
import {Config} from "tailwindcss"
import defaultTheme from "tailwindcss/defaultTheme"

export default {
  content: ["./index.html", "./src/**/*.{js,ts,jsx,tsx}"],
  theme: {
    extend: {
      colors: {
        "cr-black": "black",
        "cr-white": "white",
        "cr-blue": {
          DEFAULT: "rgb(0, 34, 238)",
          dark: "rgb(0, 16, 102)",
          mid: "rgb(72, 96, 241)",
          "mid-light": "rgb(199, 210, 254)",
          light: "rgb(224, 231, 255)",
          "super-light": "rgb(247, 249, 255)",
        },
        "cr-indigo": {
          mid: "rgb(153, 167, 248)",
        },
        "cr-cyan": {
          DEFAULT: "rgb(15, 221, 221)",
          dark: "rgb(17, 177, 177)",
          mid: "rgb(86, 227, 226)",
          "mid-light": "rgb(118, 240, 240)",
          light: "rgb(183, 245, 245)",
        },
        "cr-grey": {
          "5": "rgb(242, 242, 242)",
          "15": "rgb(217, 217, 217)",
          "30": "rgb(178, 178, 178)",
          "50": "rgb(128, 128, 128)",
          "65": "rgb(89, 89, 89)",
          "80": "rgb(51, 51, 51)",
          "90": "rgb(25, 25, 25)",
        },
        "cr-red": {
          DEFAULT: "rgb(255, 16, 16)",
          dark: "rgb(186,28,28)",
          "mid-light": "rgb(255,162,162)",
          light: "rgb(255,183,183)",
        },
        "cr-green": {DEFAULT: "rgb(1, 169, 52)", light: "rgb(179, 228, 195)"},
        "cr-yellow": {DEFAULT: "rgb(255, 159, 1)", light: "rgb(248,231,204)"},
      } as const,
      animation: {
        launch: "launch .75s cubic-bezier(.25,0,0,1) 1",
        "fade-in": "fade-in 1s linear",
        "loading-fade-in": "loading-fade-in 1.5s linear",
        "loading-spinner": "spin 1.5s ease-in-out infinite",
        "loading-container": "spin 6s linear infinite",
      },
      keyframes: {
        launch: {
          "0%, 100%": {top: "0"},
          "50%": {top: "-4em"},
          "50.01%": {top: "4em"},
        },
        "loading-fade-in": {
          "0%, 33%": {opacity: "0"},
          "100%": {opacity: "1"},
        },
        "fade-in": {
          "0%": {opacity: "0"},
          "100%": {opacity: "1"},
        },
      },
      backgroundSize: {
        "size-200": "200% 200%",
        "size-300": "300% 300%",
      },
      backgroundPosition: {
        "pos-0": "0% 0%",
        "pos-100": "100% 100%",
      },
      screens: {
        "max-sm": {raw: `not all and (min-width: ${defaultTheme.screens.sm})`},
        "max-md": {raw: `not all and (min-width: ${defaultTheme.screens.md})`},
        "max-lg": {raw: `not all and (min-width: ${defaultTheme.screens.lg})`},
        "max-xl": {raw: `not all and (min-width: ${defaultTheme.screens.xl})`},
        "max-2xl": {raw: `not all and (min-width: ${defaultTheme.screens["2xl"]})`},

        tall: {
          raw: "(min-height: 800px)",
        },
      },
    },
  },
  plugins: [twForms],
} satisfies Config
