import {ELanguage} from "../../i18n.ts"
import {TRollbarUserInfo} from "../../services"
import {
  CURRENT_LS_VERSIONS,
  deleteAllLSItems,
  getAllLSItems,
  setLSItem,
  TLSAllTimeKey,
  TLSCurrentKey,
  TLSItemWithKey,
  TLSVersion,
} from "./index"

export const UPDATERS = [
  // Language 'en' is now 'en-gb' for date formatting reasons
  makeUpdater({
    fromKey: "language",
    fromVersion: 1,
    migrateFn: ({data}) => ({version: 2, key: "language", data: data === "en" ? ELanguage.EN : (data as ELanguage)}),
  }),

  // Users now have a 'isMasqueraded' flag
  makeUpdater({
    fromKey: "lastUser",
    fromVersion: 1,
    migrateFn: ({data}) => ({
      version: 2,
      key: "lastUser",
      data: {...data, isMasqueraded: false} as TRollbarUserInfo,
    }),
  }),
] as const

export function updateAllLSItems(updaters: readonly TVersionUpdater[] = UPDATERS) {
  const allItems = getAllLSItems()

  deleteAllLSItems()

  allItems.forEach(item => {
    const updatedItem = getUpdatedLSItem({updaters, item})

    if (!updatedItem || updatedItem.version !== CURRENT_LS_VERSIONS[updatedItem.key as TLSCurrentKey]) {
      return
    }

    setLSItem(updatedItem.key as TLSCurrentKey, updatedItem.data)
  })
}

function getUpdatedLSItem({
  updaters,
  item,
}: {
  updaters: readonly TVersionUpdater[]
  item: TLSItemWithKey
}): TLSItemWithKey | null {
  const updater = updaters.find(u => u.fromKey === item.key && u.fromVersion === item.version)

  if (!updater) {
    return item
  }

  const updatedItem = updater.migrateFn(item)
  return updatedItem && getUpdatedLSItem({updaters, item: updatedItem})
}

type TVersionUpdater<
  TFromKey extends TLSAllTimeKey = any,
  TFromVersion extends TLSVersion<TFromKey> = any,
  TToKey extends TLSAllTimeKey = any,
  TToVersion extends TLSVersion<TToKey> = any,
> = {
  fromKey: TFromKey
  fromVersion: TFromVersion
  /**
   * Return `null` to remove the item
   */
  migrateFn: (old: TLSItemWithKey<TFromKey, TFromVersion>) => TLSItemWithKey<TToKey, TToVersion> | null
}

function makeUpdater<
  TFromKey extends TLSAllTimeKey = any,
  TFromVersion extends TLSVersion<TFromKey> = any,
  TToKey extends TLSAllTimeKey = any,
  TToVersion extends TLSVersion<TToKey> = any,
>(ret: TVersionUpdater<TFromKey, TFromVersion, TToKey, TToVersion>): typeof ret {
  return ret
}
