import React from "react"
import {Trans, useTranslation} from "react-i18next"
import {useQueryClient} from "@tanstack/react-query"

import Button from "../../../../components/Button.tsx"
import {Link} from "../../../../components/Link.tsx"
import {ConfirmModal} from "../../../../components/Modal.tsx"
import {getCurrentLanguage} from "../../../../i18n.ts"
import {useUserSettingsOrLogout} from "../../../../queries/user.ts"
import api, {salesCycleDetailGeneralKey} from "../../../../services"
import requestError from "../../../../services/requestError.tsx"
import {ASubscription, ASubscriptionStatusEnum} from "../../../../services/types.generated.ts"
import {apiDateToJS} from "../../../../utils/dateArithmetics.ts"
import {commonTransComponents} from "../../../../utils/i18n.tsx"
import {isCompanyUser} from "../../../../utils/types.ts"
import {Section, Subsection} from "./DetailsTabSection.tsx"

const StopSalesPlanModal: React.FC<{
  subscription: ASubscription
  isOpen: boolean
  onClose: () => void
}> = ({subscription, isOpen, onClose}) => {
  const {t} = useTranslation()
  const queryClient = useQueryClient()

  const {id, nearest_service_termination_date} = subscription

  const handleStopSalesPlan = React.useCallback(async () => {
    try {
      await api.subscriptions.cancelCreate(id, {subscription: {cancel_reason: null}})
      onClose()
    } catch (e) {
      requestError(e)
    } finally {
      queryClient.invalidateQueries({queryKey: salesCycleDetailGeneralKey})
    }
  }, [id, onClose, queryClient])

  return (
    <ConfirmModal
      title={t("SellingDetails_Details_StopPlan_Modal_Title")}
      isOpen={isOpen}
      size={"sm"}
      variant={"error"}
      noIcon
      cancelButtonText={t("SellingDetails_Details_StopPlan_Modal_Cancel")}
      confirmButtonText={t("SellingDetails_Details_StopPlan_Modal_Submit")}
      onClose={onClose}
      onConfirm={handleStopSalesPlan}
    >
      <div className={"flex flex-col gap-4 pt-2"}>
        <p>
          {t("SellingDetails_Details_StopPlan_Modal_Note", {
            date: nearest_service_termination_date
              ? apiDateToJS(nearest_service_termination_date).toLocaleString(getCurrentLanguage(), {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })
              : "-",
          })}
        </p>
        <p>
          <Trans
            i18nKey={"SellingDetails_Details_StopPlan_Modal_ContactSupport"}
            components={{
              ...commonTransComponents,
              contactSupportLink: <Link to={"mailto:support@closerocket.com"} />,
            }}
          />
        </p>
      </div>
    </ConfirmModal>
  )
}

export const StopSalesPlanSection: React.FC<{
  subscription: ASubscription
}> = ({subscription}) => {
  const {t} = useTranslation()
  const {user} = useUserSettingsOrLogout()

  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const {status, service_termination_date, nearest_service_termination_date} = subscription

  const Wrapper = React.useCallback(
    ({children}: {children: React.ReactNode}) => {
      return (
        <Section
          title={t("SellingDetails_Details_StopPlan_Title")}
          subtitle={t("SellingDetails_Details_StopPlan_Subtitle")}
        >
          {children}
          <StopSalesPlanModal subscription={subscription} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </Section>
      )
    },
    [isModalOpen, subscription, t]
  )

  if (!isCompanyUser(user)) {
    return null
  }

  if (service_termination_date && [ASubscriptionStatusEnum.Active, ASubscriptionStatusEnum.Canceled].includes(status)) {
    return (
      <Wrapper>
        <Subsection title={t("SellingDetails_Details_StopPlan_SubscriptionCanceled_Title")}>
          <Trans
            i18nKey={"SellingDetails_Details_StopPlan_SubscriptionCanceled_Text"}
            components={commonTransComponents}
            values={{
              date: apiDateToJS(service_termination_date).toLocaleString(getCurrentLanguage(), {
                day: "numeric",
                month: "long",
                year: "numeric",
              }),
            }}
          />
        </Subsection>
      </Wrapper>
    )
  }

  if (nearest_service_termination_date && status === ASubscriptionStatusEnum.Active) {
    return (
      <Wrapper>
        <div className={"flex w-full grow items-center justify-end"}>
          <Button color={"red"} variant={"outlined"} onClick={() => setIsModalOpen(true)}>
            {t("SellingDetails_Details_StopPlan_Button")}
          </Button>
        </div>
      </Wrapper>
    )
  }

  return null
}
