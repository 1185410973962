import React from "react"
import {Trans, useTranslation} from "react-i18next"
import {ArrowDownTrayIcon, PlusIcon} from "@heroicons/react/20/solid"
import {
  BuildingOfficeIcon,
  CheckCircleIcon,
  IdentificationIcon,
  MapPinIcon,
  PuzzlePieceIcon,
} from "@heroicons/react/24/outline"

import {Link} from "../../components/Link"
import {ProfileImage} from "../../components/ProfileImage.tsx"
import {Tooltip} from "../../components/Tooltip"
import {ACommissionValueTypes, ASalesPersonDashboard} from "../../services/types.generated"
import {renderPrice} from "../../utils"
import {commonTransComponents, enumTranslKey} from "../../utils/i18n"
import {CommissionTooltip} from "./CommissionTooltip.tsx"
import {SalaryTooltip} from "./SalaryTooltip.tsx"
import SellingOfferActions from "./SellingOfferActions"
import {getCommissionValueTranslated} from "./utils.tsx"

type TOffer = ASalesPersonDashboard["assignment_offers"][number]

export const SellingOffer: React.FC<{offer: TOffer; refetch: () => void}> = ({offer, refetch}) => {
  return (
    <div className={"card border-cr-blue p-0"}>
      <div className={"border-b border-b-cr-blue px-14 py-6"}>
        <TopPanel offer={offer} refetch={refetch} />
      </div>

      <div className={"flex max-lg:flex-col"}>
        <div className={"grow px-14 py-10 lg:border-r lg:border-r-cr-blue max-lg:border-b max-lg:border-b-cr-blue"}>
          <ProductPanel salesCycle={offer.sales_cycle} />
        </div>
        <div className={"grow px-14 py-10"}>
          <GoalPanel offer={offer} />
        </div>
      </div>

      <div className={"rounded-b-lg border-t border-t-cr-blue bg-cr-blue-light px-14 py-6"}>
        <BottomPanel offer={offer} />
      </div>
    </div>
  )
}

export const ProductPanel: React.FC<{salesCycle: TOffer["sales_cycle"]}> = ({salesCycle}) => {
  const {t} = useTranslation()

  return (
    <div className={"flex flex-col gap-10"}>
      <Section title={t("SalespersonDashboard_NewOffer_Caption_SalesPlanName")}>
        <h3 className={"text-2xl font-semibold"}>{salesCycle.name}</h3>
      </Section>
      <Section title={t("SalespersonDashboard_NewOffer_Caption_ProductAttributes")}>
        <div className={"flex flex-col gap-10"}>
          {salesCycle.elevator_pitch && <p className={"whitespace-pre-line"}>{salesCycle.elevator_pitch}</p>}

          <Attributes salesCycle={salesCycle} />
        </div>
      </Section>
    </div>
  )
}

export const Attributes: React.FC<{
  salesCycle: Pick<TOffer["sales_cycle"], "country" | "segment" | "decision_maker_persona" | "ideal_customer_profile">
}> = ({salesCycle}) => {
  const {t} = useTranslation()

  return (
    <div className={"flex flex-wrap gap-x-14 gap-y-7"}>
      <Attribute title={t("SalespersonDashboard_NewOffer_Caption_TargetMarket")} Icon={MapPinIcon}>
        {salesCycle.country.name}
      </Attribute>

      <Attribute title={t("SalespersonDashboard_NewOffer_Caption_Segment")} Icon={PuzzlePieceIcon}>
        {t(enumTranslKey("Segment", salesCycle.segment))}
      </Attribute>

      <Attribute title={t("SalespersonDashboard_NewOffer_Caption_DecisionMaker")} Icon={IdentificationIcon}>
        {salesCycle.decision_maker_persona.map(persona => t(enumTranslKey("Persona", persona), persona)).join(", ")}
      </Attribute>

      <Attribute title={t("SalespersonDashboard_NewOffer_Caption_CustomerProfile")} Icon={BuildingOfficeIcon}>
        {t(enumTranslKey("Profile", salesCycle.ideal_customer_profile))}
      </Attribute>
    </div>
  )
}

export const GoalPanel: React.FC<{offer: TOffer}> = ({offer}) => {
  const {t} = useTranslation()
  const goal = offer.products[0].goal

  return (
    <div className={"flex flex-col gap-10"}>
      <Section title={t("SalespersonDashboard_NewOffer_Caption_Goal")}>
        <h3 className={"text-2xl font-semibold text-cr-blue"}>
          {t(enumTranslKey("ProductGoal", offer.products[0].goal))}
        </h3>
      </Section>

      <div className={"flex items-end gap-9 max-sm:flex-col max-sm:items-start max-sm:gap-4"}>
        <Section
          title={t("SalespersonDashboard_NewOffer_Caption_Salary")}
          tooltip={<SalaryTooltip goal={goal} reaches={offer.reaches} minimumMeetings={offer.minimum_meetings} />}
        >
          <div>
            {
              // This arbitrary splitting the 100€ to bonus was explicitly requested by Stano.
              // Let it be known I was not happy.
            }
            <h4 className={"whitespace-nowrap text-2xl font-semibold"}>
              {renderPrice(offer.reward_value - 100, offer.currency, {noDecimal: true})}
            </h4>

            <div className={"whitespace-nowrap text-sm text-cr-grey-50"}>
              <Trans
                i18nKey={"SalespersonDashboard_Selling_Pay_Subtitle"}
                components={commonTransComponents}
                values={{
                  bonus: renderPrice(100, offer.currency ?? undefined, {
                    noDecimal: true,
                  }),
                }}
              />
            </div>
          </div>
        </Section>

        <PlusIcon className={"h-10 w-10"} />

        <Section
          title={t("SalespersonDashboard_NewOffer_Caption_Commission")}
          tooltip={<CommissionTooltip goal={goal} />}
        >
          <h4 className={"whitespace-nowrap text-2xl font-semibold"}>
            {getCommissionValueTranslated(
              offer.products[0].commission_type,
              offer.products[0].commission_value_type === ACommissionValueTypes.FixedPrice
                ? renderPrice(offer.products[0].sales_person_commission, offer.currency, {
                    noDecimal: true,
                  })
                : `${offer.products[0].sales_person_commission}%`
            )}
          </h4>
        </Section>
      </div>

      <Section title={t("SalespersonDashboard_NewOffer_Caption_Reaches")}>
        <h4 className={"whitespace-nowrap text-2xl font-semibold"}>
          {t("SalespersonDashboard_NewOffer_Reaches", {count: offer.reaches})}
        </h4>
      </Section>
    </div>
  )
}

export const BottomPanel: React.FC<{offer: TOffer}> = ({offer}) => {
  const {t} = useTranslation()

  return (
    <div className={"flex justify-between gap-10"}>
      <div className={"flex items-center gap-2 text-cr-blue-dark"}>
        {offer.sales_cycle.worked_with_same_company && (
          <>
            <CheckCircleIcon className={"inline-block h-5 w-5"} />{" "}
            {t("SalespersonDashboard_You worked here previously")}
          </>
        )}
      </div>

      <div className={"flex items-center gap-10"}>
        <Link
          download
          to={offer.sales_cycle.price_list_url ?? ""}
          className={"flex items-center gap-2 font-bold"}
          target={"_blank"}
          rel={"noreferrer"}
          disabled={!offer.sales_cycle.price_list_url}
          title={offer.sales_cycle.price_list_url ?? t("T_Price list is not available for this sales plan")}
          flipUnderline
        >
          <ArrowDownTrayIcon className={"h-5"} />
          {t("T_Price list")}
        </Link>

        <Link
          download
          to={offer.sales_cycle.sales_strategy_url ?? ""}
          className={"flex items-center gap-2 font-bold"}
          target={"_blank"}
          rel={"noreferrer"}
          disabled={!offer.sales_cycle.sales_strategy_url}
          title={offer.sales_cycle.sales_strategy_url ?? t("T_Sales strategy is not available for this sales plan")}
          flipUnderline
        >
          <ArrowDownTrayIcon className={"h-5"} />
          {t("T_Strategy")}
        </Link>
      </div>
    </div>
  )
}

export const TopPanel: React.FC<{offer: TOffer; refetch: () => void}> = ({offer, refetch}) => {
  return (
    <div className={"flex items-center justify-between gap-4 max-sm:flex-col"}>
      <div className={"flex items-center gap-4"}>
        <ProfileImage
          className={"size-14"}
          src={offer.sales_cycle.company.profile_picture_thumbnail_url ?? undefined}
          alt={offer.sales_cycle.company.name}
        />
        <h3 className={"text-2xl font-semibold"}>{offer.sales_cycle.company.name}</h3>
      </div>

      <div className={"flex items-center"}>
        <SellingOfferActions offer={offer} refetch={refetch} />
      </div>
    </div>
  )
}

const Section: React.FC<React.PropsWithChildren<{title: React.ReactNode; tooltip?: React.ReactNode}>> = ({
  title,
  tooltip,
  children,
}) => {
  return (
    <div className={"flex flex-col gap-4"}>
      <div className={"flex gap-2 whitespace-nowrap"}>
        <h4 className={"whitespace-nowrap text-sm font-bold text-cr-grey-50"}>{title}</h4>
        {tooltip && (
          <Tooltip iconClassName={"text-cr-grey-50 w-5 h-5"} buttonClassName={"data-[open]:outline-cr-grey-50"}>
            <div className={"card px-6 py-4"}>{tooltip}</div>
          </Tooltip>
        )}
      </div>
      {children}
    </div>
  )
}

const Attribute: React.FC<
  React.PropsWithChildren<{Icon: React.ComponentType<{className?: string}>; title: React.ReactNode}>
> = ({title, Icon, children}) => {
  return (
    <div className={"flex gap-5"}>
      <div
        className={
          "flex h-12 w-12 items-center justify-center rounded-lg border-2 border-cr-blue-mid bg-cr-blue-light text-cr-blue-mid"
        }
      >
        <Icon className={"h-8 w-8"} />
      </div>
      <div className={"flex flex-col gap-1"}>
        <div className={"text-sm text-cr-grey-50"}>{title}</div>
        <div className={"font-semibold"}>{children}</div>
      </div>
    </div>
  )
}
