import React from "react"
import {useTranslation} from "react-i18next"

import {GenericErrorAlert} from "../../../components/Alert"
import {LayoutBlock} from "../../../components/Layout/LayoutBlock"
import {Loading} from "../../../components/Loading"
import {EOrderDirection} from "../../../components/Table/shared.ts"
import {sortDate} from "../../../components/Table/sortFunctions.ts"
import {
  TAdminsProspectsIteration,
  useBulkDisapproveProspectsMutation,
  useProspectsSalesCycleQuery,
} from "../../../queries/prospects"
import {AAdminsProspectsSalesCycle} from "../../../services/types.generated"
import {apiDateToJS} from "../../../utils/dateArithmetics.ts"
import {useDocumentTitle, useNumParam} from "../../../utils/hooks"
import {lifetimeSymbol, PeriodToggle, TPeriodToggleValue} from "../../Reports/components/PeriodToggle.tsx"
import {AddProspectsModal, EFeature} from "../shared/AddProspectsModal"
import {
  AssignmentContext,
  DeletingContext,
  EditingContext,
  IsUploadingContext,
  RejectContextProvider,
  TProspectContext,
  TRejectProspect,
} from "../shared/context"
import {DeleteModal} from "../shared/DeleteModal.tsx"
import {EditingFlyout} from "../shared/EditingFlyout.tsx"
import {useActiveAssignment, useActiveIteration} from "../shared/hooks"
import {RejectModal, TRejectSubmitParams} from "../shared/RejectModal"
import {StatusBanner} from "./components/StatusBanner"
import {ProspectsIteration} from "./ProspectsIteration.tsx"
import {ProspectsLifetime} from "./ProspectsLifetime.tsx"

export const AdminProspects: React.FC = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("T_Prospects"))

  const salesCycleId = useNumParam("salesCycleId")
  const {
    data: salesCycleData,
    error: salesCycleError,
    isPending: isSalesCycleLoading,
  } = useProspectsSalesCycleQuery(salesCycleId)

  if (isSalesCycleLoading) {
    return <Loading size={"xl"} />
  }

  if (salesCycleError || !salesCycleData) {
    return <GenericErrorAlert />
  }

  return <ProspectsLoaded salesCycle={salesCycleData as AAdminsProspectsSalesCycle} />
}

export const ProspectsLoaded: React.FC<{
  salesCycle: AAdminsProspectsSalesCycle
}> = ({salesCycle}) => {
  const [activeIteration, setActiveIteration] = useActiveIteration(salesCycle)
  const [activeAssignment] = useActiveAssignment(activeIteration)

  const isUploadingContextValue = IsUploadingContext.useProviderValue(false)
  const editingContextValue = EditingContext.useProviderValue(null)
  const deleteContextValue = DeletingContext.useProviderValue(null)

  const assignmentContextValue = AssignmentContext.useProviderValue({assignment: null, iteration: null, salesCycle})
  const {setValue: setAssignmentContext} = assignmentContextValue

  const [rejectingProspects, setRejectingProspects] = React.useState<TRejectProspect[] | null>(null)
  const rejectContextValue = React.useMemo<TProspectContext>(
    () => ({
      prospects: rejectingProspects,
      setProspects: setRejectingProspects,
      rejectionsLeft: null,
    }),
    [rejectingProspects]
  )

  const bulkDisapproveMutation = useBulkDisapproveProspectsMutation()

  const handleRejectSubmit = React.useCallback(
    (params: TRejectSubmitParams) => {
      return bulkDisapproveMutation.mutateAsync({
        ids: params.prospects.map(({id}) => id),
        disapprove_reason: params.disapprove_reason,
        disapprove_reason_text: params.disapprove_reason_text,
      })
    },
    [bulkDisapproveMutation]
  )

  React.useEffect(() => {
    if (!activeIteration || !activeAssignment) {
      setAssignmentContext({assignment: null, iteration: null, salesCycle})
      return
    }

    setAssignmentContext({assignment: activeAssignment, iteration: activeIteration, salesCycle})
  }, [setAssignmentContext, activeAssignment, activeIteration, salesCycle])

  const iterationOptions = React.useMemo<string[]>(() => {
    return [...salesCycle.sales_cycle_iterations]
      .sort(sortDate<TAdminsProspectsIteration>(iteration => apiDateToJS(iteration.start_date))(EOrderDirection.DESC))
      .map(iteration => iteration.start_date)
  }, [salesCycle.sales_cycle_iterations])

  const handleChangeIteration = React.useCallback(
    (startDate: TPeriodToggleValue) => {
      setActiveIteration(salesCycle.sales_cycle_iterations.find(iteration => iteration.start_date === startDate))
    },
    [salesCycle.sales_cycle_iterations, setActiveIteration]
  )

  return (
    <AssignmentContext.Provider value={assignmentContextValue}>
      <IsUploadingContext.Provider value={isUploadingContextValue}>
        <DeletingContext.Provider value={deleteContextValue}>
          <EditingContext.Provider value={editingContextValue}>
            <RejectContextProvider value={rejectContextValue}>
              <div className={"flex flex-col gap-9 py-16"}>
                <LayoutBlock innerClassName={"flex items-start justify-between"}>
                  <PeriodToggle
                    periods={iterationOptions}
                    value={activeIteration?.start_date ?? lifetimeSymbol}
                    onChange={handleChangeIteration}
                  />

                  {activeIteration && <StatusBanner iteration={activeIteration} />}
                </LayoutBlock>

                {activeIteration ? (
                  <ProspectsIteration salesCycle={salesCycle} />
                ) : (
                  <ProspectsLifetime salesCycle={salesCycle} />
                )}
              </div>

              <EditingFlyout />
              <DeleteModal />
              <AddProspectsModal enabledFeatures={[EFeature.XLS, EFeature.Leads]} />
              {rejectingProspects && <RejectModal onSubmit={handleRejectSubmit} />}
            </RejectContextProvider>
          </EditingContext.Provider>
        </DeletingContext.Provider>
      </IsUploadingContext.Provider>
    </AssignmentContext.Provider>
  )
}
