import React, {useEffect} from "react"
import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router-dom"
import {useQueryClient} from "@tanstack/react-query"

import {Loading} from "../components/Loading"
import api from "../services"
import requestError from "../services/requestError"
import {useDocumentTitle} from "../utils/hooks"

const LogOut = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("T_Log out"))

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  useEffect(
    () =>
      void (async () => {
        await api.users.signOutDelete().catch(requestError)
        await queryClient.resetQueries()
        navigate("/log-in", {replace: true})
      })(),
    [queryClient, navigate]
  )

  return <Loading size={"xl"} />
}

export default LogOut
