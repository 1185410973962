import React from "react"
import {useTranslation} from "react-i18next"
import {ArrowLeftIcon, ArrowRightIcon} from "@heroicons/react/20/solid"
import {twMerge} from "tailwind-merge"

import {GenericErrorAlert} from "../../../components/Alert.tsx"
import Button from "../../../components/Button.tsx"
import {LayoutBlock} from "../../../components/Layout/LayoutBlock.tsx"
import {Loading} from "../../../components/Loading.tsx"
import {EOrderDirection} from "../../../components/Table/shared.ts"
import {sortDate} from "../../../components/Table/sortFunctions.ts"
import {TabsContainer} from "../../../components/Tabs.tsx"
import {
  TSaasCompanyProspectsIteration,
  useProspectsSalesCycleQuery,
  useSaasCompanyFinishedTableQuery,
} from "../../../queries/prospects.ts"
import {ASaasCompanyProspectsSalesCycle} from "../../../services/types.generated.ts"
import {apiDateToJS} from "../../../utils/dateArithmetics.ts"
import {useDocumentTitle, useNumParam, useParam} from "../../../utils/hooks.tsx"
import {useHorizontalSnapScroll} from "../../../utils/useHorizontalSnapScroll.tsx"
import {PeriodToggle, TPeriodToggleValue} from "../../Reports/components/PeriodToggle.tsx"
import {AddProspectsModal, EFeature} from "../shared/AddProspectsModal"
import {AssignmentContext, DeletingContext, EditingContext, IsUploadingContext} from "../shared/context.ts"
import {DeleteModal} from "../shared/DeleteModal.tsx"
import {EditingFlyout} from "../shared/EditingFlyout.tsx"
import {useActiveAssignment, useActiveIteration} from "../shared/hooks.ts"
import {NoProspects} from "../shared/NoProspects.tsx"
import {AssignmentPackage} from "./components/AssignmentPackage.tsx"
import {Section} from "./Section.tsx"

export const SaasCompanyProspects: React.FC = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("T_Prospects"))

  const salesCycleId = useNumParam("salesCycleId")
  const {
    data: salesCycleData,
    error: salesCycleError,
    isPending: isSalesCycleLoading,
  } = useProspectsSalesCycleQuery(salesCycleId)

  if (isSalesCycleLoading) {
    return <Loading size={"xl"} />
  }

  if (salesCycleError || !salesCycleData) {
    return <GenericErrorAlert />
  }

  return <ProspectsLoaded salesCycle={salesCycleData as ASaasCompanyProspectsSalesCycle} />
}

const ProspectsLoaded: React.FC<{salesCycle: ASaasCompanyProspectsSalesCycle}> = ({salesCycle}) => {
  const {t} = useTranslation()
  const isAddButtonVisible = useParam("show_admin_buttons", true) === "true"

  const [activeIteration, setActiveIteration] = useActiveIteration(salesCycle)
  const [activeAssignment, setActiveAssignment] = useActiveAssignment(activeIteration)

  const isUploadingContextValue = IsUploadingContext.useProviderValue(false)
  const editingContextValue = EditingContext.useProviderValue(null)
  const deleteContextValue = DeletingContext.useProviderValue(null)

  const {onScroll, containerClassName, currentItemRef, containerRef, leftButton, rightButton} =
    useHorizontalSnapScroll()

  const assignmentContextValue = AssignmentContext.useProviderValue(null)
  const {setValue: setAssignmentContext} = assignmentContextValue

  React.useEffect(() => {
    if (!activeIteration || !activeAssignment) {
      setAssignmentContext(null)
      return
    }

    setAssignmentContext({assignment: activeAssignment, iteration: activeIteration, salesCycle})
  }, [setAssignmentContext, activeAssignment, activeIteration, salesCycle])

  const iterationOptions = React.useMemo<string[]>(() => {
    return [...salesCycle.sales_cycle_iterations]
      .sort(
        sortDate<TSaasCompanyProspectsIteration>(iteration => apiDateToJS(iteration.start_date))(EOrderDirection.DESC)
      )
      .map(iteration => iteration.start_date)
  }, [salesCycle.sales_cycle_iterations])

  const handleChangeIteration = React.useCallback(
    (startDate: TPeriodToggleValue) => {
      setActiveIteration(salesCycle.sales_cycle_iterations.find(iteration => iteration.start_date === startDate))
    },
    [salesCycle.sales_cycle_iterations, setActiveIteration]
  )

  if (!activeIteration || !activeAssignment) {
    return <Loading />
  }

  return (
    <AssignmentContext.Provider value={assignmentContextValue}>
      <IsUploadingContext.Provider value={isUploadingContextValue}>
        <DeletingContext.Provider value={deleteContextValue}>
          <EditingContext.Provider value={editingContextValue}>
            <LayoutBlock outerClassName={"py-16"} innerClassName={"flex flex-col gap-9"}>
              <div>
                <PeriodToggle
                  periods={iterationOptions}
                  value={activeIteration.start_date}
                  onChange={handleChangeIteration}
                  noLifetime
                />
              </div>

              <TabsContainer className={"min-h-20"}>
                <Button
                  disabled={leftButton.disabled}
                  onClick={leftButton.onClick}
                  shape={"circular"}
                  wrapperClassName={"self-center"}
                >
                  <ArrowLeftIcon className={"w-6"} />
                </Button>

                <div
                  className={twMerge(["no-scrollbar flex grow scroll-mt-20 gap-10", containerClassName])}
                  ref={containerRef}
                  onScroll={onScroll}
                >
                  {activeIteration?.assignments.map(assignmentPackage => (
                    <AssignmentPackage
                      key={assignmentPackage.id}
                      ref={assignmentPackage.id === activeAssignment.id ? currentItemRef : undefined}
                      onClick={() => setActiveAssignment(assignmentPackage)}
                      active={assignmentPackage.id === activeAssignment.id}
                      assignmentPackage={assignmentPackage}
                    />
                  ))}
                </div>

                <Button
                  disabled={rightButton.disabled}
                  onClick={rightButton.onClick}
                  shape={"circular"}
                  wrapperClassName={"self-center"}
                >
                  <ArrowRightIcon className={"w-6"} />
                </Button>
              </TabsContainer>

              {activeAssignment.prospects_finished_count > 0 ? (
                <Section
                  title={
                    <>
                      {t("Prospects_Finished_Title", {count: activeAssignment.prospects_finished_count})}
                      <span className={"ml-2 text-sm font-normal"}>
                        {t("Prospects_Finished_Subtitle", {
                          approved: activeAssignment.prospects_approved_count,
                          rejected:
                            activeAssignment.prospects_finished_count - activeAssignment.prospects_approved_count,
                        })}
                      </span>
                    </>
                  }
                  salesCycleId={salesCycle.id}
                  assignmentId={activeAssignment.id}
                  isAddButtonVisible={isAddButtonVisible}
                  isCRMButtonVisible={data => data?.data.meta?.sync_crm_button ?? false}
                  listQuery={useSaasCompanyFinishedTableQuery}
                  paginationKey={"prospects saas company finished"}
                />
              ) : (
                <NoProspects isAddButtonVisible={isAddButtonVisible} />
              )}
            </LayoutBlock>

            <EditingFlyout />
            <DeleteModal />
            <AddProspectsModal enabledFeatures={[EFeature.XLS]} />
          </EditingContext.Provider>
        </DeletingContext.Provider>
      </IsUploadingContext.Provider>
    </AssignmentContext.Provider>
  )
}
