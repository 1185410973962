import {faker} from "@faker-js/faker"
import {format} from "date-fns/format"

import {
  AActivitiesChartData,
  AActivitiesValues,
  AActivityFeed,
  AActivityFeedActivityType,
  AActivityFeedNotificationType,
  AActivityFeedUser,
  AActivityFeedUserType,
  AActivityOccurrencesChartData,
  AAssignmentStatuses,
  ACommissionTypes,
  ACommissionValueTypes,
  ACompany,
  ACompanyUserAssignmentReporting,
  ACompanyUserDashboard,
  ACompanyUserDashboardActiveSalesCycle,
  ACompanyUserDashboardDraftedSalesCycle,
  ACompanyUserDashboardFinishedSalesCycle,
  ACompanyUserDashboardOrder,
  ACompanyUserSalesCycleDetails,
  ACompanyUserSalesCycleIterationAssignmentReporting,
  ACompanyUserSalesCycleIterationReporting,
  ACompanyUserSalesCycleProspects,
  ACompanyUserSalesCycleReporting,
  ACompanyUserSalesCycleSalesNews,
  ACompanyUserSalesCycleSalesPerson,
  ACompanyUserSalesNewsAssignment,
  ACompanyUserSalesPerson,
  ACompanyUsersScopedStatistics,
  ACountry,
  ACurrency,
  AGoals,
  AIndexMetadataListData,
  ALanguage,
  ALead,
  ALeadCompanySizes,
  AOnboardingStatuses,
  AOpportunitiesChartData,
  AOrder,
  AOrderItem,
  AOrderOrderTypeEnum,
  AOrderStatuses,
  APackageWithPrice,
  APagination,
  APaymentMethods,
  APreviewOrder,
  AProduct,
  APublicLead,
  ASalesCycleFilterStages,
  ASalesCycleIdealCustomerProfiles,
  ASalesCycleIterationStatuses,
  ASalesCycleProspectStatuses,
  ASalesCycleSegment,
  ASalesCyclesNewsAndInsightsDetailData,
  ASalesCyclesSalesPeopleDetailData,
  ASalesCycleStatuses,
  ASalesCycleYearsOfMarkets,
  ASalesPersonSalesCycleSalesNews,
  ASettings,
  ASubscription,
  ASubscriptionBillingTypes,
  ASubscriptionRecurrenceEnum,
  ASubscriptionStatusEnum,
  AUsersSalesPerson,
  AUserTypes,
  AWelcomeScreen,
} from "../../services/types.generated"

export function makeEntity<T extends Record<string, any>>(base: T) {
  return (extension: Partial<T> = {}): T => ({...base, ...extension})
}
export const makeCountry = () =>
  makeEntity<ACountry>({name: faker.location.country(), code: faker.location.countryCode(), id: faker.number.int()})

export const makePublicLead = () =>
  makeEntity<APublicLead>({
    organization_name: "mockOrg",
    phone_number: "mockPhone",
    additional_phone_number: "mockAdditionalPhone",
    position: "mockPosition",
    organization_phone_number: "mockOrgPhone",
    contact_person_last_name: "mockFirstName",
    contact_person_first_name: "mockLastName",
    email: "mock@email.com",
    website: "www.mock.website",
    city: "mockCity",
    segment: ["fmcg", "mockSegment"],
    country: makeCountry()(),
  })

export const makeLead = makeEntity<ALead>({
  id: 1,
  name: "mockLeadName",
  in_use: false,
  company_size: ALeadCompanySizes.Corporation,
  notes: "mockNotes",
  source: "mockSource",
  organization_number: "123",
  organization_name: "mockOrg",
  phone_number: "mockPhone",
  additional_phone_number: "mockAdditionalPhone",
  position: "mockPosition",
  organization_phone_number: "mockOrgPhone",
  contact_person_last_name: "mockFirstName",
  contact_person_first_name: "mockLastName",
  email: "mock@email.com",
  website: "www.mock.website",
  city: "mockCity",
  segment: ["fmcg", "mockSegment"],
  country: makeCountry()(),
})

export const makeLeadsMetadata = makeEntity<AIndexMetadataListData["leads_metadata"]>({
  total_leads_in_db: 12,
  removal_requested: 6,
  import_in_progress: true,
})

export const makePagination = makeEntity<APagination>({total_count: 12, current_page: 1, per_page: 10})

export const makeStatistics = () =>
  makeEntity<ACompanyUsersScopedStatistics>({
    lost: faker.number.int({min: 0, max: 100}),
    won: faker.number.int({min: 0, max: 100}),
    calls_made: faker.number.int({min: 0, max: 100}),
    activities: faker.number.int({min: 0, max: 100}),
    clients_reached: faker.number.int({min: 0, max: 100}),
    opportunities: faker.number.int({min: 0, max: 100}),
    meetings_done: faker.number.int({min: 0, max: 100}),
    emails_sent: faker.number.int({min: 0, max: 100}),
  })

export const makeReportsTrends = () =>
  makeEntity<ACompanyUsersScopedStatistics>({
    lost: faker.number.int({min: -100, max: 100}),
    won: faker.number.int({min: -100, max: 100}),
    calls_made: faker.number.int({min: -100, max: 100}),
    activities: faker.number.int({min: -100, max: 100}),
    clients_reached: faker.number.int({min: -100, max: 100}),
    opportunities: faker.number.int({min: -100, max: 100}),
    meetings_done: faker.number.int({min: -100, max: 100}),
    emails_sent: faker.number.int({min: -100, max: 100}),
  })

export const makeReportsSalesperson = () => {
  const picture = faker.image.avatar()

  return makeEntity<NonNullable<ACompanyUserAssignmentReporting["sales_person"]>>({
    id: faker.number.int(),
    profile_picture_thumbnail_url: picture,
    profile_picture_url: picture,
    first_name: faker.person.firstName(),
    last_name: faker.person.lastName(),
  })
}

export const makeActivitiesValues = () =>
  makeEntity<AActivitiesValues>({
    calls: faker.number.int({min: 0, max: 100}),
    emails: faker.number.int({min: 0, max: 100}),
    meetings: faker.number.int({min: 0, max: 100}),
  })

export const makeOpportunitiesValues = () =>
  makeEntity<AOpportunitiesChartData>({
    future_deals: faker.number.int({min: 0, max: 100}),
    proposal_sent: faker.number.int({min: 0, max: 100}),
    negotiating: faker.number.int({min: 0, max: 100}),
  })

export const makeActivitiesChartDataAssignment = () =>
  makeEntity<NonNullable<AActivitiesChartData[number]["assignments"]>[number]>({
    id: faker.number.int(),
    values: makeActivitiesValues()(),
    sales_person: makeReportsSalesperson()(),
  })

export const makeActivitiesChartDatum = () =>
  makeEntity<AActivitiesChartData[number]>({
    start_date: format(faker.date.anytime(), "yyyy-MM-dd"),
    end_date: format(faker.date.anytime(), "yyyy-MM-dd"),
    values: makeActivitiesValues()(),
    assignments: [
      makeActivitiesChartDataAssignment()(),
      makeActivitiesChartDataAssignment()(),
      makeActivitiesChartDataAssignment()(),
    ],
  })

export const makeActivityOccurrencesChartDatum = () =>
  makeEntity<AActivityOccurrencesChartData[number]>({
    occurrences: faker.number.int({min: 0, max: 100}),
    from: format(faker.date.anytime(), "yyyy-MM-dd"),
    to: format(faker.date.anytime(), "yyyy-MM-dd"),
    key: `${faker.number.int()}`,
  })

export const makeReportsAssignment = () =>
  makeEntity<ACompanyUserAssignmentReporting>({
    id: faker.number.int(),
    total_activities: faker.number.int(),
    activities_trend: faker.number.int({min: -100, max: 100}),
    sales_person: makeReportsSalesperson()(),
    group_id: String(faker.number.int()),
  })

export const makeReportsIterationDetail = () =>
  makeEntity<ACompanyUserSalesCycleIterationReporting>({
    id: faker.number.int(),
    permissions: {"destroy?": false, "update?": false},
    assignments: [makeReportsAssignment()(), makeReportsAssignment()(), makeReportsAssignment()()],
    statistics: {counters: makeStatistics()(), trends: makeReportsTrends()()},
  })

export const makeReportsAssignmentDetail = () =>
  makeEntity<ACompanyUserSalesCycleIterationAssignmentReporting>({
    id: faker.number.int(),
    permissions: {"destroy?": false, "update?": false},
    statistics: {counters: makeStatistics()(), trends: makeReportsTrends()()},
  })

export const makeReportsNewsAndInsights = () =>
  makeEntity<NonNullable<ASalesCyclesNewsAndInsightsDetailData["sales_cycle_iteration"]>>({
    id: faker.number.int(),
    month: faker.date.month(),
    news: `News: ${faker.lorem.paragraph()}`,
    insights: `Insights: ${faker.lorem.paragraph()}`,
  })

export const makeReportsSalesCycleProspect = () =>
  makeEntity<ACompanyUserSalesCycleProspects>({
    id: faker.number.int(),
    notes: `Note: ${faker.lorem.paragraph()}`,
    organization_name: faker.company.name(),
    status: ASalesCycleProspectStatuses.Won,
    segment: [],
    stage: ASalesCycleFilterStages.SecondOutreach,
  })

export const makeReportsSalesCycleIteration = () =>
  makeEntity<ACompanyUserSalesCycleReporting["sales_cycle_iterations"][number]>({
    id: faker.number.int(),
    start_date: format(faker.date.anytime(), "yyyy-MM-dd"),
    end_date: format(faker.date.anytime(), "yyyy-MM-dd"),
    finished_report: false,
    report_sent_by_super_admin: false,
  })

export const makeReportsSalesCycle = () =>
  makeEntity<ACompanyUserSalesCycleReporting>({
    id: faker.number.int(),
    status: ASalesCycleStatuses.Ongoing,
    permissions: {"destroy?": false, "update?": false},
    statistics: {counters: makeStatistics()(), trends: makeReportsTrends()()},
    assignments: [makeReportsAssignment()(), makeReportsAssignment()(), makeReportsAssignment()()],
    sales_cycle_iterations: [
      makeReportsSalesCycleIteration()(),
      makeReportsSalesCycleIteration()(),
      makeReportsSalesCycleIteration()(),
    ],
    hide_meeting_activities: false,
    name: faker.commerce.productName(),
    company_name: faker.company.name(),
    max_assignments_count_per_iteration: 3,
  })

export const makeUserSettingsSaaSCompany = () => {
  const avatar = faker.image.avatar()

  return makeEntity<ASettings>({
    welcome_screen: false,
    permissions: {},
    payments: {
      publishable_api_key: "mockApiKey",
      vat_required_country_codes: [],
    },
    currencies: [],
    countries: [],
    user: {
      id: faker.number.int(),
      company: {
        id: faker.number.int(),
        name: faker.company.name(),
        profile_picture_url: avatar,
        profile_picture_thumbnail_url: avatar,
      },
      email: faker.internet.email(),
      first_name: faker.person.firstName(),
      last_name: faker.person.lastName(),
      type: AUserTypes.SaasCompanyUser,
      hero_section_visible: false,
      profile_picture_url: avatar,
      profile_picture_thumbnail_url: avatar,
      phone_number: faker.phone.number(),
    },
  })
}

export const makeUserSettingsSaaSSalesperson = () => {
  const avatar = faker.image.avatar()

  return makeEntity<ASettings>({
    welcome_screen: false,
    permissions: {},
    payments: {
      publishable_api_key: "mockApiKey",
      vat_required_country_codes: [],
    },
    currencies: [],
    countries: [],
    user: {
      id: faker.number.int(),
      company: {
        id: faker.number.int(),
        name: faker.company.name(),
        profile_picture_url: avatar,
        profile_picture_thumbnail_url: avatar,
      },
      email: faker.internet.email(),
      first_name: faker.person.firstName(),
      last_name: faker.person.lastName(),
      type: AUserTypes.SaasSalesPerson,
      hero_section_visible: false,
      profile_picture_url: avatar,
      profile_picture_thumbnail_url: avatar,
      phone_number: faker.phone.number(),
    },
  })
}

export const makeUserSettingsCompany = () => {
  const avatar = faker.image.avatar()

  return makeEntity<ASettings>({
    welcome_screen: false,
    permissions: {},
    payments: {
      publishable_api_key: "mockApiKey",
      vat_required_country_codes: [],
    },
    currencies: [],
    countries: [],
    user: {
      id: faker.number.int(),
      company: {
        id: faker.number.int(),
        name: faker.company.name(),
        profile_picture_url: avatar,
        profile_picture_thumbnail_url: avatar,
      },
      email: faker.internet.email(),
      first_name: faker.person.firstName(),
      last_name: faker.person.lastName(),
      type: AUserTypes.CompanyUser,
      hero_section_visible: false,
      profile_picture_url: avatar,
      profile_picture_thumbnail_url: avatar,
      phone_number: faker.phone.number(),
    },
  })
}

export const makeUserSettingsAdmin = () => {
  const avatar = faker.image.avatar()

  return makeEntity<ASettings>({
    welcome_screen: false,
    permissions: {},
    payments: {
      publishable_api_key: "mockApiKey",
      vat_required_country_codes: [],
    },
    currencies: [],
    countries: [],
    user: {
      type: AUserTypes.Admin,
      id: faker.number.int(),
      email: faker.internet.email(),
      first_name: faker.person.firstName(),
      last_name: faker.person.lastName(),
      hero_section_visible: false,
      profile_picture_url: avatar,
      profile_picture_thumbnail_url: avatar,
      video_introduction_viewed: true,
      phone_number: faker.phone.number(),
    },
  })
}

export const makeUserSettingsSalesperson = () => {
  const avatar = faker.image.avatar()

  return makeEntity<ASettings>({
    welcome_screen: false,
    permissions: {},
    payments: {
      publishable_api_key: "mockApiKey",
      vat_required_country_codes: [],
    },
    currencies: [],
    countries: [],
    user: {
      type: AUserTypes.SalesPerson,
      id: faker.number.int(),
      email: faker.internet.email(),
      first_name: faker.person.firstName(),
      last_name: faker.person.lastName(),
      hero_section_visible: false,
      profile_picture_url: avatar,
      profile_picture_thumbnail_url: avatar,
      video_introduction_viewed: true,
      phone_number: faker.phone.number(),
    } satisfies AUsersSalesPerson,
  })
}

export const makeCompanyUserSalesPerson = () => {
  const avatar = faker.image.avatar()

  return makeEntity<ACompanyUserSalesPerson>({
    id: faker.number.int(),
    first_name: faker.person.firstName(),
    last_name: faker.person.lastName(),
    profile_picture_url: avatar,
    profile_picture_thumbnail_url: avatar,
  })
}

export const makeCompanyUserSalesCycle = () =>
  makeEntity<ACompanyUserSalesCycleDetails>({
    id: faker.number.int(),
    name: faker.commerce.productName(),
    country: makeCountry()(),
    status: ASalesCycleStatuses.Ongoing,
    permissions: undefined,
    attachments: undefined,
    decision_maker_persona: [],
    default_currency: undefined,
    years_of_market: ASalesCycleYearsOfMarkets.ThreeAndMore,
    elevator_pitch: faker.commerce.productDescription(),
    first_start_date: format(faker.date.anytime(), "yyyy-MM-dd"),
    ideal_customer_profile: ASalesCycleIdealCustomerProfiles.Corporation,
    price_list_url: undefined,
    product_benefits: [],
    product_uniques: undefined,
    products: [],
    references: undefined,
    sales_person_offline_required: false,
    sales_person_online_required: false,
    sales_strategy_url: null,
    segment: ASalesCycleSegment.Corporate,
    website: undefined,
    started_at: format(faker.date.anytime(), "yyyy-MM-dd"),
    story_description: faker.lorem.paragraph(),
    strategy_finalized: true,
    successful_events: [],
    hide_meeting_activities: false,
    this_month_iteration_id: faker.number.int(),
    sales_talent_prospects_enabled: true,
  })

export const makeCompanySalesCycleSalesNews = () =>
  makeEntity<ACompanyUserSalesCycleSalesNews>({
    id: faker.number.int(),
    country: makeCountry()(),
    name: faker.commerce.productName(),
    status: ASalesCycleStatuses.Ongoing,
    decision_maker_persona: [],
    hide_meeting_activities: false,
    elevator_pitch: faker.commerce.productDescription(),
    ideal_customer_profile: ASalesCycleIdealCustomerProfiles.Corporation,
    segment: ASalesCycleSegment.Corporate,
    product_benefits: [],
    products: [],
    years_of_market: ASalesCycleYearsOfMarkets.ThreeAndMore,
    sales_person_offline_required: false,
    sales_person_online_required: false,
    started_at: format(faker.date.anytime(), "yyyy-MM-dd"),
    iteration_start_date: format(faker.date.anytime(), "yyyy-MM-dd"),
    statistics: makeStatistics()(),
    assignments: [makeSalesCycleSalesNewsAssignment()(), makeSalesCycleSalesNewsAssignment()()],
    sales_talent_prospects_enabled: true,
  })

export const makeSalespersonSalesCycleSalesNews = () =>
  makeEntity<ASalesPersonSalesCycleSalesNews>({
    id: faker.number.int(),
    country: makeCountry()(),
    name: faker.commerce.productName(),
    status: ASalesCycleStatuses.Ongoing,
    decision_maker_persona: [],
    hide_meeting_activities: false,
    elevator_pitch: faker.commerce.productDescription(),
    ideal_customer_profile: ASalesCycleIdealCustomerProfiles.Corporation,
    segment: ASalesCycleSegment.Corporate,
    product_benefits: [],
    products: [],
    years_of_market: ASalesCycleYearsOfMarkets.ThreeAndMore,
    sales_person_offline_required: false,
    sales_person_online_required: false,
    started_at: format(faker.date.anytime(), "yyyy-MM-dd"),
    iteration_start_date: format(faker.date.anytime(), "yyyy-MM-dd"),
    statistics: makeStatistics()(),
    sales_talent_prospects_enabled: true,
  })

export const makeSalesCycleSalesNewsAssignment = () =>
  makeEntity<ACompanyUserSalesNewsAssignment>({
    id: faker.number.int(),
    statistics: makeStatistics()(),
    sales_person: makeCompanyUserSalesPerson()(),
    reaches: faker.number.int({min: 1, max: 100}),
    outreach: faker.number.int({min: 0, max: 100}),
    permissions: {"destroy?": false, "update?": false},
  })

export const makeSalesCycleSubscription = () =>
  makeEntity<ASubscription>({
    activated_at: null,
    cancel_requested_at: null,
    id: faker.number.int(),
    nearest_cancel_date: format(faker.date.future(), "yyyy-MM-dd"),
    nearest_service_termination_date: format(faker.date.future(), "yyyy-MM-dd"),
    recurrence: ASubscriptionRecurrenceEnum.Month,
    scheduled_cancel_date: null,
    service_termination_date: null,
    status: ASubscriptionStatusEnum.Active,
  })

const makeActivityUser = () => {
  const avatar = faker.image.avatar()

  return makeEntity<AActivityFeedUser>({
    type: AActivityFeedUserType.Admin,
    profile_picture_thumbnail_url: avatar,
    profile_picture_url: avatar,
    first_name: faker.person.firstName(),
    last_name: faker.person.lastName(),
    id: faker.number.int(),
  })
}

export const makeActivity = () =>
  makeEntity<AActivityFeed>({
    updated_at: faker.date.anytime().toISOString(),
    created_at: faker.date.anytime().toISOString(),
    activity_type: AActivityFeedActivityType.StringNotification,
    id: faker.number.int(),
    activity_date: format(faker.date.anytime(), "yyyy-MM-dd"),
    liked_by_company: false,
    string_notification_type: AActivityFeedNotificationType.WeeklyUpdate,
    body: faker.lorem.paragraphs({min: 1, max: 3}),
    grouped: false,
    grouped_count: 0,
    grouped_at: null,
    created_by: makeActivityUser()(),
    deal: {},
    changed_activity_at: faker.date.anytime().toISOString(),
  })

export const makeWelcomeScreen = () => {
  return makeEntity<AWelcomeScreen>({
    sales_people: [],
    onboarding_status: AOnboardingStatuses.RequestSalesTalents,
  })
}

export const makeRequestedSalesPerson = () => {
  return makeEntity<NonNullable<AWelcomeScreen["requested_sales_person_assignments"]>[number]>({
    id: faker.number.int(),
    reaches: faker.number.int({min: 1, max: 100}),
    sales_person: makeSalesCycleSalesPerson()(),
    status: "prematching",
  })
}

export const makeCompanyDashboardAssignmentSalesPerson = () => {
  return makeEntity<NonNullable<ACompanyUserDashboardActiveSalesCycle["assignments"]>[number]>({
    id: faker.number.int(),
    reaches: faker.number.int({min: 1, max: 100}),
    sales_person: makeCompanyUserSalesPerson()(),
    status: AAssignmentStatuses.Active,
  })
}

export const makeCompanyUserDashboard = () => {
  return makeEntity<ACompanyUserDashboard>({
    id: faker.number.int(),
    active_sales_cycles_count: faker.number.int({min: 1, max: 100}),
    drafted_sales_cycles_count: faker.number.int({min: 1, max: 100}),
    paused_sales_cycles_count: faker.number.int({min: 1, max: 100}),
    finished_sales_cycles_count: faker.number.int({min: 1, max: 100}),
    sales_people: [makeSalesCycleSalesPerson()(), makeSalesCycleSalesPerson()(), makeSalesCycleSalesPerson()()],
  })
}

export const makeCompany = () => {
  const avatar = faker.image.urlLoremFlickr({category: "logo"})

  return makeEntity<ACompany>({
    id: faker.number.int(),
    name: faker.company.name(),
    profile_picture_url: avatar,
    profile_picture_thumbnail_url: avatar,
  })
}

export const makeCompanyUserActiveSalesCycle = () => {
  return makeEntity<ACompanyUserDashboardActiveSalesCycle>({
    id: faker.number.int(),
    company: makeCompany()(),
    country: makeCountry()(),
    name: faker.commerce.productName(),
    status: ASalesCycleIterationStatuses.Active,
    activities: faker.number.int({min: 1, max: 100}),
    opportunities: faker.number.int({min: 1, max: 100}),
    first_selling_date: format(faker.date.anytime(), "yyyy-MM-dd"),
    current_selling_date: format(faker.date.anytime(), "yyyy-MM-dd"),
    order: makeCompanyDashboardOrder()(),
    assignments: [makeCompanyDashboardAssignmentSalesPerson()(), makeCompanyDashboardAssignmentSalesPerson()()],
  })
}

export const makeCompanyUserDraftSalesCycle = () => {
  return makeEntity<ACompanyUserDashboardDraftedSalesCycle>({
    id: faker.number.int(),
    company: makeCompany()(),
    country: makeCountry()(),
    name: faker.commerce.productName(),
    onboarding_status: AOnboardingStatuses.RequestSalesTalents,
    key_details_finalized: false,
    strategy_finalized: false,
    strategy_touched: false,
  })
}

export const makeCompanyUserFinishedSalesCycle = () => {
  return makeEntity<ACompanyUserDashboardFinishedSalesCycle>({
    id: faker.number.int(),
    company: makeCompany()(),
    country: makeCountry()(),
    name: faker.commerce.productName(),
  })
}

export const makeCurrency = makeEntity<ACurrency>({
  id: 1,
  name: "Euro",
  code: "EUR",
  symbol: "€",
})

export const makeSalesCycleSalesPersonPackage = () => {
  const monthlyPrice = faker.number.int({min: 500, max: 2000})

  return makeEntity<APackageWithPrice>({
    id: faker.number.int(),
    reaches: faker.helpers.arrayElement([40, 80]),
    name: faker.lorem.word(2),
    short_name: faker.lorem.word(1),
    currency: makeCurrency(),
    monthly_price: monthlyPrice,
    subtitle: faker.lorem.paragraph(),
    title: faker.lorem.sentence(),
    yearly_price: monthlyPrice * 12,
  })
}

export const makeSalesCyclePlaceholderSalesPerson = () => {
  return makeEntity<NonNullable<ASalesCyclesSalesPeopleDetailData["meta"]>["sales_person_placeholder"]>({
    country: makeCountry()(),
    packages: [makeSalesCycleSalesPersonPackage()(), makeSalesCycleSalesPersonPackage()()],
  })
}

export const makeSalesCycleSalesPerson = () => {
  const avatar = faker.image.urlLoremFlickr({category: "face"})

  return makeEntity<ACompanyUserSalesCycleSalesPerson>({
    id: faker.number.int(),
    country: makeCountry()(),
    embed_video_id: faker.string.alphanumeric({length: 11}),
    first_name: faker.person.firstName(),
    rating: faker.number.float({min: 0, max: 5}),
    native_language_id: 1,
    spoken_language_ids: [2, 3],
    years_of_experience: faker.number.int({min: 1, max: 20}),
    profile_picture_url: avatar,
    profile_picture_thumbnail_url: avatar,
    packages: [makeSalesCycleSalesPersonPackage()(), makeSalesCycleSalesPersonPackage()()],
  })
}

export const makeLanguage = () => {
  return makeEntity<ALanguage>({
    id: faker.number.int(),
    name: faker.location.country(),
    code: faker.location.countryCode("alpha-2"),
  })
}

export const makeOrderItem = () => {
  const pkg = makeSalesCycleSalesPersonPackage()()

  return makeEntity<AOrderItem>({
    id: faker.number.int(),
    total_price: faker.number.int({min: 100, max: 1000}),
    total_price_without_vat: faker.number.int({min: 100, max: 1000}),
    total_price_before_discount: faker.number.int({min: 100, max: 1000}),
    name: "Big order",
    description: faker.lorem.paragraph(),
    unit_price: faker.number.int({min: 100, max: 1000}),
    unit_price_without_vat: faker.number.int({min: 100, max: 1000}),
    total_vat_price: faker.number.int({min: 100, max: 1000}),
    discount_percentage: 0,
    vat_rate_percentage: 0,
    quantity: 1,
    unit_price_before_discount_without_vat: faker.number.int({min: 100, max: 1000}),
    unit_price_before_discount: faker.number.int({min: 100, max: 1000}),
    total_price_before_discount_without_vat: faker.number.int({min: 100, max: 1000}),
    unit_discount_price: faker.number.int({min: 100, max: 1000}),
    unit_vat_price: faker.number.int({min: 100, max: 1000}),
    total_discount_price: faker.number.int({min: 100, max: 1000}),
    /** This attribute can be Package or SalesStrategy */
    purchased_product: pkg,
    package: pkg,
    sales_strategy: null,
    sales_person: makeSalesCycleSalesPerson()(),
  })
}

export const makeOrder = () => {
  return makeEntity<AOrder>({
    id: faker.number.int(),
    order_type: AOrderOrderTypeEnum.Subscription,
    status: AOrderStatuses.Created,
    subscription_billing_type: ASubscriptionBillingTypes.Month,
    total_price: faker.number.int({min: 500, max: 50000}),
    total_price_without_vat: faker.number.int({min: 500, max: 50000}),
    total_price_before_discount: faker.number.int({min: 500, max: 50000}),
    total_vat_price: faker.number.int({min: 500, max: 50000}),
    total_price_before_discount_without_vat: faker.number.int({min: 500, max: 50000}),
    total_discount_price: faker.number.int({min: 500, max: 50000}),
    customer_name: faker.person.fullName(),
    customer_email: faker.internet.email(),
    city: faker.location.city(),
    address: faker.location.streetAddress(),
    postal_code: faker.location.zipCode(),
    company_name: faker.company.name(),
    company_number: String(faker.number.int({min: 1000000, max: 9999999})),
    company_tax_number: String(faker.number.int({min: 1000000, max: 9999999})),
    company_vat_number: String(faker.number.int({min: 1000000, max: 9999999})),
    external_invoice_url: faker.internet.url(),
    external_receipt_url: faker.internet.url(),
    invoice_number: String(faker.number.int({min: 1000000, max: 9999999})),
    next_payment_date: format(faker.date.anytime(), "yyyy-MM-dd"),
    next_payment_total_price: faker.number.int({min: 1000, max: 10000}),
    payment_method: APaymentMethods.Undefined,
    sales_cycle: makeCompanyUserSalesCycle()(),
    country: makeCountry()(),
    currency: makeCurrency(),
    order_items: [makeOrderItem()(), makeOrderItem()(), makeOrderItem()()],
    service_items: [makeOrderItem()()],
  })
}

export const makeCompanyDashboardOrder = () => {
  return makeEntity<ACompanyUserDashboardOrder>({
    id: faker.number.int(),
    status: AOrderStatuses.Paid,
    total_price: faker.number.int({min: 500, max: 50000}),
    currency: makeCurrency(),
    external_invoice_url: faker.internet.url(),
    payment_method: APaymentMethods.BankTransfer,
    due_date: format(faker.date.anytime(), "yyyy-MM-dd"),
  })
}

export const makePreviewOrder = () => {
  return makeEntity<APreviewOrder>({
    order: makeOrder()(),
    min_regular_setup_fee_price: faker.number.int({min: 1000, max: 10000}),
    max_regular_setup_fee_price: faker.number.int({min: 1000, max: 10000}),
    min_regular_sales_person_price: faker.number.int({min: 1000, max: 10000}),
    max_regular_sales_person_price: faker.number.int({min: 1000, max: 10000}),
    min_total_regular_price: faker.number.int({min: 1000, max: 10000}),
    max_total_regular_price: faker.number.int({min: 1000, max: 10000}),
  })
}

export const makeProduct = () => {
  const commission = faker.number.int({min: 1, max: 100})
  return makeEntity<AProduct>({
    id: faker.number.int(),
    name: faker.commerce.productName(),
    commission,
    commission_type: ACommissionTypes.OneOff,
    currency: makeCurrency(),
    goal: AGoals.Registration,
    commission_value_type: ACommissionValueTypes.Percentage,
    price: faker.number.int({min: 10, max: 10000}),
    sales_person_commission: commission / 2,
  })
}
