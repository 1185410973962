import React from "react"
import {useTranslation} from "react-i18next"
import {ArrowTopRightOnSquareIcon} from "@heroicons/react/24/outline"
import {twMerge} from "tailwind-merge"

import {GenericErrorAlert} from "../../../components/Alert"
import {AutocompleteFilter} from "../../../components/AutocompleteFilter"
import {IconButton} from "../../../components/Button.tsx"
import {EnumFilter} from "../../../components/EnumFilter"
import {ExpandableBox} from "../../../components/ExpandableBox"
import {InputBase} from "../../../components/fields/Input"
import Pagination from "../../../components/Pagination"
import {RowCountPickerLS, usePageSize} from "../../../components/RowCountPicker.tsx"
import {Table} from "../../../components/Table"
import {TColumnsMetaWithEmpty} from "../../../components/Table/shared"
import {Tooltip} from "../../../components/Tooltip.tsx"
import {getCurrentLanguage} from "../../../i18n"
import {getReportsProspectsAutocompleteQuery, useReportsProspectsQuery} from "../../../queries/reports"
import {useUserSettingsOrLogout} from "../../../queries/user.ts"
import {
  ACompanyUserSalesCycleProspects,
  ASalesCycleFilterStages,
  ASalesCycleProspectAutocompleteFields,
  ASalesCycleProspectStages,
  AUserTypes,
} from "../../../services/types.generated"
import {EMPTY_ARRAY} from "../../../utils"
import {apiDateToJS} from "../../../utils/dateArithmetics.ts"
import {enumToOptions, enumTranslKey} from "../../../utils/i18n"
import {ActivityFeedFlyout} from "../components/ActivityFeedFlyout.tsx"
import {DownloadReportButton} from "../components/DownloadReportButton"
import {StatusBadgeCell} from "../components/StatusBadgeCell"
import {
  ActivityFeedContext,
  TLastChangeOption,
  TProspectsTableColumn,
  TStageOption,
  TStatusOption,
  useCurrentAssignmentId,
  useCurrentSelectionState,
  useProspectsFiltering,
} from "../hooks"

const lastUpdateOptions = enumToOptions("LastUpdate") as TLastChangeOption[]

const prospectStatusOptions = enumToOptions("ProspectStatus") as TStatusOption[]

const paginationKey = "reports prospects section"

export const ProspectsSection: React.FC = () => {
  const {t} = useTranslation()
  const prospectsFiltering = useProspectsFiltering()

  const {user} = useUserSettingsOrLogout()
  const isAdminUser = user.type === AUserTypes.Admin
  const {
    value: {salesCycleId, iterationId},
  } = useCurrentSelectionState()
  const assignmentId = useCurrentAssignmentId()

  const activityFeedContextValue = ActivityFeedContext.useProviderValue(null)

  const {
    orderBy,
    setOrderBy,
    searchString,
    searchStringDebounced,
    setSearchString,
    lastUpdateFilter,
    lastUpdateFilterDebounced,
    setLastUpdateFilter,
    segmentFilter,
    segmentFilterDebounced,
    setSegmentFilter,
    stageFilter,
    stageFilterDebounced,
    setStageFilter,
    statusFilter,
    statusFilterDebounced,
    setStatusFilter,
    resetAllFilters,
  } = prospectsFiltering

  const {data, error, pagination, isFetching, refetch} = useReportsProspectsQuery({
    salesCycleId,
    iterationId,
    assignmentId,
    orderBy,
    pageSize: usePageSize(paginationKey),
    lastUpdate: lastUpdateFilterDebounced?.value,
    searchString: searchStringDebounced,
    segments: segmentFilterDebounced.map(opt => opt.value),
    stages: stageFilterDebounced.map(opt => opt.value),
    statuses: statusFilterDebounced.map(opt => opt.value),
  })
  const prospects = (data?.data.prospects ?? EMPTY_ARRAY) as ACompanyUserSalesCycleProspects[]

  const prospectStageOptions = React.useMemo<TStageOption[]>(() => {
    const stagesUsed = data?.data.meta?.stages_used ?? []
    const stagesSelected = stageFilter.map(option => option.value)

    const stagesToShow =
      stagesUsed.length > 0
        ? Array.from(new Set([...stagesUsed, ...stagesSelected]))
        : (Object.values(ASalesCycleProspectStages) as unknown as ASalesCycleFilterStages[])

    return (enumToOptions("ProspectStage") as TStageOption[]).filter(({value}) => stagesToShow.includes(value))
  }, [data?.data.meta?.stages_used, stageFilter])

  // reset all filters when iteration changes
  React.useEffect(() => {
    resetAllFilters()
  }, [iterationId, resetAllFilters])

  const totalProspectsCount = data?.data.meta?.total_prospects_in_db

  const columnsMeta = React.useMemo<TColumnsMetaWithEmpty<TProspectsTableColumn, ACompanyUserSalesCycleProspects>>(
    () => [
      isAdminUser && {
        column: "action",
        HeaderCellValue: () => t("Reports_Prospects_Table_Action_Title"),
        CellValue: ({row}) => {
          const url = row.crm_url
          const isDisabled = !url

          return (
            <Tooltip
              disabled={isDisabled}
              buttonNode={
                <IconButton
                  className={twMerge("bg-cr-white", isDisabled ? "text-cr-grey-30" : "hover:bg-cr-grey-5")}
                  disabled={isDisabled}
                  onClick={url ? () => window.open(url, "_blank") : undefined}
                  noEdges
                >
                  <ArrowTopRightOnSquareIcon className={"size-5"} />
                </IconButton>
              }
              closeDelay={0}
            >
              <div className={"rounded-lg bg-cr-black px-4 py-2 text-sm text-cr-white"}>
                {t("Reports_Prospects_Table_Action_OpenCRM")}
              </div>
            </Tooltip>
          )
        },
      },
      {
        column: "status",
        HeaderCellValue: () => t("Reports_Prospects_Table_Status"),
        CellValue: StatusBadgeCell,
      },
      {
        column: "organization_name",
        sortFn: true,
        HeaderCellValue: () => t("Reports_Prospects_Table_Organization"),
        CellValue: ({row}) => <span className={"font-bold"}>{row.organization_name}</span>,
      },
      {
        column: "notes",
        HeaderCellValue: () => t("Reports_Prospects_Table_Notes"),
        CellValue: ({row}) => (row.notes ? <ExpandableBox>{row.notes}</ExpandableBox> : "-"),
      },
      {
        column: "segment",
        HeaderCellValue: () => t("Reports_Prospects_Table_Segment"),
        CellValue: ({row}) => row.segment?.join(", ") ?? "-",
      },
      {
        column: "stage",
        HeaderCellValue: () => t("Reports_Prospects_Table_Stage"),
        CellValue: ({row}) => (row.stage ? t(enumTranslKey("ProspectStage", row.stage)) : "-"),
      },
      {
        column: "deal_details",
        HeaderCellValue: () => t("Reports_Prospects_Table_DealDetails"),
        CellValue: ({row}) => (row.deal_details ? <ExpandableBox>{row.deal_details}</ExpandableBox> : "-"),
      },
      {
        column: "meeting_at",
        HeaderCellValue: () => t("Reports_Prospects_Table_Meeting"),
        CellValue: ({row}) =>
          row.meeting_at ? apiDateToJS(row.meeting_at).toLocaleString(getCurrentLanguage(), {dateStyle: "long"}) : "-",
      },
      {
        column: "lost_reason",
        HeaderCellValue: () => t("Reports_Prospects_Table_LostReason"),
        CellValue: ({row}) => (row.lost_reason ? <ExpandableBox>{row.lost_reason}</ExpandableBox> : "-"),
      },
      {
        column: "position",
        HeaderCellValue: () => t("Reports_Prospects_Table_Position"),
      },
    ],
    [isAdminUser, t]
  )

  if (error) {
    return (
      <ProspectsCard>
        <GenericErrorAlert retry={refetch} />
      </ProspectsCard>
    )
  }

  return (
    <ActivityFeedContext.Provider value={activityFeedContextValue}>
      <ProspectsCard actions={<DownloadReportButton />}>
        <div className={"flex flex-wrap-reverse items-center justify-end gap-6"}>
          <div className={"font-semibold"}>{t("Reports_Prospects_Table_Filters")}:</div>
          {iterationId == null && (
            <EnumFilter
              options={lastUpdateOptions}
              title={t("Reports_Prospects_Table_LastUpdate")}
              multiple={false}
              value={lastUpdateFilter}
              onChange={setLastUpdateFilter}
            />
          )}
          <AutocompleteFilter
            title={t("Reports_Prospects_Table_Segment")}
            value={segmentFilter}
            useAutocompleteQuery={getReportsProspectsAutocompleteQuery(
              salesCycleId,
              ASalesCycleProspectAutocompleteFields.Segment
            )}
            onChange={setSegmentFilter}
          />
          <EnumFilter
            multiple
            options={prospectStageOptions}
            title={t("Reports_Prospects_Table_Stage")}
            value={stageFilter}
            onChange={setStageFilter}
          />
          <EnumFilter
            multiple
            options={prospectStatusOptions}
            title={t("Reports_Prospects_Table_Status")}
            value={statusFilter}
            onChange={setStatusFilter}
          />
          <InputBase
            value={searchString}
            onChange={e => setSearchString(e.target.value)}
            placeholder={t("SearchPlaceholder")}
          />
        </div>
        <div className={"overflow-auto"}>
          <Table<TProspectsTableColumn, ACompanyUserSalesCycleProspects>
            className={"min-w-max"}
            data={prospects}
            loading={isFetching}
            orderBy={orderBy}
            onOrder={setOrderBy}
            ghost
            columnsMeta={columnsMeta}
          >
            {({data}) => (
              <>
                {data.length > 0 &&
                  data.map(row => (
                    <Table.Row
                      key={row.id}
                      testId={`row-${row.id}`}
                      row={row}
                      className={"max-w-md whitespace-pre-wrap px-6 py-4"}
                      onClick={() => activityFeedContextValue.setValue(row)}
                    />
                  ))}
                {data.length === 0 && !isFetching && (
                  <div className={"col-span-full py-7 text-center text-sm"}>
                    {totalProspectsCount
                      ? t("Reports_Prospects_Table_NoProspects_Filtered")
                      : t("Reports_Prospects_Table_NoProspects_Total")}
                  </div>
                )}
              </>
            )}
          </Table>
        </div>
        <RowCountPickerLS total={pagination.total} autoHide paginationKey={paginationKey} />
        <Pagination {...pagination} autoHide />
      </ProspectsCard>
      <ActivityFeedFlyout />
    </ActivityFeedContext.Provider>
  )
}

const ProspectsCard: React.FC<{children: React.ReactNode; actions?: React.ReactNode}> = ({children, actions}) => {
  const {t} = useTranslation()

  return (
    <div className={"card-shadow flex grow flex-col gap-6 p-6"}>
      <div className={"flex justify-between"}>
        <h2 className={"font-bold"}>{t("Reports_Prospects_Title")}</h2>
        {actions}
      </div>
      {children}
    </div>
  )
}
