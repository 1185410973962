import React, {useCallback} from "react"
import {useTranslation} from "react-i18next"

import {Loading} from "../../components/Loading"
import {TUploadablePictureOnChange, UploadablePicture} from "../../components/UploadablePicture"
import {useSalespersonQuery, useUpdateSalespersonPictureMutation} from "../../queries/salespeople"
import requestError from "../../services/requestError.tsx"
import {getFullName} from "../../utils"
import {useDocumentTitle, useNumParam} from "../../utils/hooks"
import {getApiValidationMessages, isAxiosValidationError} from "../../utils/validation"
import {SalespersonProfile} from "./SalespersonProfile.tsx"

export const Salesperson: React.FC = () => {
  const {t} = useTranslation()
  const salespersonId = useNumParam("salespersonId")

  useDocumentTitle(t("SalespersonProfile_Profile"))

  return (
    <div className={"flex justify-center p-10"}>
      <div className={"flex max-w-full grow flex-col gap-8 xl:max-w-screen-xl"}>
        <ProfilePicture salespersonId={salespersonId} />

        <div className={"min-w-0"}>
          <SalespersonProfile />
        </div>
      </div>
    </div>
  )
}

export const ProfilePicture: React.FC<{salespersonId: number}> = ({salespersonId}) => {
  const {t} = useTranslation()
  const {data, status} = useSalespersonQuery(salespersonId)
  const updateSalespersonPictureMutation = useUpdateSalespersonPictureMutation()

  const handleChange = useCallback<TUploadablePictureOnChange>(
    async (file: File) => {
      try {
        await updateSalespersonPictureMutation.mutateAsync({salespersonId, file})
      } catch (e) {
        if (!isAxiosValidationError(e)) {
          requestError(e)
          return
        }

        return {message: getApiValidationMessages(e.response.data, "errors").join(", ")}
      }
    },
    [salespersonId, updateSalespersonPictureMutation]
  )

  if (status === "error") {
    return null
  }

  if (!data) {
    return <Loading size={"xl"} containerClassName={"h-[160px] my-6"} />
  }

  return (
    <div className={"my-6 flex w-full gap-8"}>
      <UploadablePicture
        url={data.profile.profile_picture_url ?? undefined}
        pencilPosition={"bottom-right"}
        onChange={handleChange}
        maxSize={10_000_000}
        maxSizeMessage={t("T_The file you have picked is too large. Maximum size 10MB.")}
        rounded
      />

      <div className={"flex grow flex-col gap-2"}>
        <span className={"text-sm text-cr-grey-50"}>{t("T_Salesperson profile")}</span>
        <h1 className={"text-2xl font-semibold"}>{getFullName(data.profile)}</h1>
      </div>
    </div>
  )
}
