import {FC, useState} from "react"
import {useTranslation} from "react-i18next"
import {toast} from "react-toastify"

import Button, {ButtonLoading} from "../../components/Button"
import {ConfirmModal} from "../../components/Modal"
import api from "../../services"
import requestError from "../../services/requestError"
import {ASalesPersonDashboard} from "../../services/types.generated"

const SellingOfferActions: FC<{
  offer: ASalesPersonDashboard["assignment_offers"][number]
  refetch: () => void
}> = ({offer, refetch}) => {
  const {t} = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  const handleAccept = () =>
    api.assignmentOffers
      .acceptPartialUpdate(offer.id)
      .then(() => toast.success(t("SalespersonDashboard_You've accepted the offer.")))
      .catch(requestError)
      .finally(() => refetch())

  const handleDecline = () =>
    api.assignmentOffers
      .rejectPartialUpdate(offer.id)
      .then(() => setIsOpen(false))
      .catch(requestError)
      .finally(() => refetch())

  return (
    <div className={"flex flex-row gap-4 pr-3"}>
      <Button variant={"outlined"} color={"gray"} onClick={() => setIsOpen(true)}>
        {t("T_Decline")}
      </Button>
      <ButtonLoading onClick={handleAccept}>{t("T_Accept")}</ButtonLoading>

      <ConfirmModal
        variant={"warning"}
        onConfirm={handleDecline}
        title={t("SalespersonDashboard_Are you sure you want to decline?")}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        cancelButtonText={
          <span>
            <b>{t("T_No")}</b>
            {t("T_, I clicked by mistake")}
          </span>
        }
        confirmButtonText={t("T_Yes, I am sure")}
      >
        {t(
          "SalespersonDashboard_We might not be able to match you with different company in your location. This company would love to grow in your city. Let's help them scale together!"
        )}
      </ConfirmModal>
    </div>
  )
}

export default SellingOfferActions
